// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-legend-js": () => import("./../../../src/pages/legend.js" /* webpackChunkName: "component---src-pages-legend-js" */),
  "component---src-pages-map-js": () => import("./../../../src/pages/map.js" /* webpackChunkName: "component---src-pages-map-js" */),
  "component---src-pages-mission-js": () => import("./../../../src/pages/mission.js" /* webpackChunkName: "component---src-pages-mission-js" */),
  "component---src-pages-pictures-js": () => import("./../../../src/pages/pictures.js" /* webpackChunkName: "component---src-pages-pictures-js" */),
  "component---src-pages-ratbadger-js": () => import("./../../../src/pages/ratbadger.js" /* webpackChunkName: "component---src-pages-ratbadger-js" */)
}

